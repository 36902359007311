<template>
  <div class="search-map">
    <div class="search-bar">
      <Select
        filterable
        :remote-method="searchQuery"
        :loading="loading"
        placeholder="请输入地址查找"
        @on-change="goPlace"
      >
        <Option
          v-for="(item, index) in queryData"
          :value="item.value"
          :key="index"
          :label="item.label"
        >
          {{ item.label }}
          <span style="float: right; color: #ccc; font-size: 12px">{{
            item.tag
          }}</span>
        </Option>
      </Select>
    </div>
    <div ref="container" class="container"></div>
  </div>
</template>

<script>
import { BMAP_AK } from "@/common/constants";
import { jsonp } from "@/common/util";
export default {
  name: "",
  props: {
    currentData: String,
    backgroundPosition: [String, Array],
  },
  data() {
    return {
      map: null,
      queryData: [],
      loading: false,
      currentMarker: null,
      label: null,

      currentLnglat: [],
    };
  },
  methods: {
    initMap() {
      return new Promise((resolve) => {
        this.map = new BMapGL.Map(this.$refs.container, {
          mapType: BMAP_EARTH_MAP,
        });
        this.map.centerAndZoom(new BMapGL.Point(106.551556, 29.563009), 9);
        this.map.enableScrollWheelZoom(true);
        resolve();
      });
    },

    mapLoad() {
      let _self = this;
      this.initBackgroundPosition();
      this.map.addEventListener("click", function (e) {
        if (_self.label) {
          _self.map.removeOverlay(_self.label);
          _self.label = null;
        }
        if (_self.currentMarker) {
          _self.map.removeOverlay(_self.currentMarker);
          _self.currentMarker = null;
        }
        _self.addMarker(e.latlng);
        _self.getLocationName(e.latlng.lng, e.latlng.lat);
      });
      if (this.currentData) {
        this.setLocation();
      }
    },

    // 加载背景地图
    initBackgroundPosition() {
      if (!this.backgroundPosition) return;
      let data =
        typeof this.backgroundPosition == "string"
          ? JSON.parse(this.backgroundPosition)
          : this.backgroundPosition;
      if (!data || !Array.isArray(data)) return;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (!this.mapPosition && i === 0 && item.zoom) {
          setTimeout(() => {
            this.map.flyTo(
              new BMapGL.Point(
                JSON.parse(item.options.center).lng,
                JSON.parse(item.options.center).lat
              ),
              +item.zoom
            );
          }, 1 * 1000);
        }
        switch (item.type) {
          case "marker":
            let center1 = new BMapGL.Point(
              JSON.parse(item.options.center).lng,
              JSON.parse(item.options.center).lat
            );
            let marker = new BMapGL.Marker(center1);
            this.map.addOverlay(marker);
            break;
          case "line":
            let path1 = JSON.parse(item.options.path).map(
              (item) => new BMapGL.Point(item.lng, item.lat)
            );
            let polyline = new BMapGL.Polyline(path1, {
              strokeWeight: item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: item.options.strokeOpacity,
            });
            this.map.addOverlay(polyline);
            break;
          case "polygon":
            let path = JSON.parse(item.options.path).map(
              (item) => new BMapGL.Point(item.lng, item.lat)
            );
            let polygon = new BMapGL.Polygon(path, {
              strokeWeight: +item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: +item.options.strokeOpacity,
              fillColor: item.options.fillColor,
              fillOpacity: +item.options.fillOpacity,
            });
            this.map.addOverlay(polygon);
            break;
          case "circle":
            let center = new BMapGL.Point(
              JSON.parse(item.options.center).lng,
              JSON.parse(item.options.center).lat
            );
            let circle = new BMapGL.Circle(center, item.options.radius, {
              strokeWeight: item.options.strokeWeight,
              strokeColor: item.options.strokeColor,
              strokeOpacity: item.options.strokeOpacity,
              fillColor: item.options.fillColor,
              fillOpacity: item.options.fillOpacity,
            });
            this.map.addOverlay(circle);
            break;
        }
      }
    },

    addMarker(latlng) {
      // let overlays = this.map.getOverlays();
      // if (overlays.length > 0) {
      //   this.map.clearOverlays();
      // }
      let point = new BMapGL.Point(latlng.lng, latlng.lat);
      let marker = new BMapGL.Marker(point);
      this.currentMarker = marker;
      this.map.addOverlay(marker);
    },

    setLocation() {
      let latlng = {
        lat: Number(this.currentData.split(",")[1]),
        lng: Number(this.currentData.split(",")[0]),
      };
      let point = new BMapGL.Point(latlng.lng, latlng.lat);
      this.map.flyTo(point, 9);
      this.addMarker(latlng);
      this.getLocationName(latlng.lng, latlng.lat);
    },

    getLocationName(lng, lat) {
      jsonp(
        `https://api.map.baidu.com/reverse_geocoding/v3/?ak=${BMAP_AK}&output=json&coordtype=wgs84ll&location=${lat},${lng}`,
        (res) => this.locationCallback(res, lng, lat)
      );
    },
    locationCallback(res, lng, lat) {
      let opts = {
        position: new BMapGL.Point(lng, lat),
        offset: new BMapGL.Size(10, -25),
      };
      let label = new BMapGL.Label(res.result.formatted_address, opts);
      label.setStyle({
        color: "#000",
        background: "none",
        border: "none",
        fontSize: "16px",
        fontFamily: "微软雅黑",
      });
      this.label = label;
      this.map.addOverlay(label);
      this.$emit("back-location", {
        address: res.result.formatted_address,
        addressComponent: res.result.addressComponent,
        lnglat: lng + "," + lat,
        thirdLongLat: lng + "," + lat,
      });
    },

    searchQuery(query) {
      if (query === "") {
        this.queryData = [];
        return;
      }
      this.loading = true;
      jsonp(
        `https://api.map.baidu.com/place/v2/suggestion?query=${query}&region=全国&output=json&ak=${BMAP_AK}`,
        this.searchCallBack
      );
    },
    searchCallBack(res) {
      if (res.status == 0) {
        this.queryData = res.result
          .filter((item) => item.location)
          .map((el) => ({
            label: el.name,
            value: `${el.location.lng},${el.location.lat}`,
            tag: `${el.province}/${el.district}`,
          }));
        this.loading = false;
      }
    },

    goPlace(value) {
      let lnglat = value.split(",");
      let point = new BMapGL.Point(lnglat[0], lnglat[1]);
      this.map.flyTo(point, 16);
    },
  },
  mounted() {
    this.initMap().then(() => {
      this.mapLoad();
    });
  },
};
</script>

<style lang="less" scoped>
.search-map {
  width: 100%;
  height: 100%;
  position: relative;
  .search-bar {
    width: 250px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100000;
  }
  .container {
    width: 100%;
    height: 100%;
  }
  .menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000001;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px;
    border-radius: 4px;
    span {
      color: #fff;
      cursor: pointer;
      padding: 5px;
      &:hover {
        color: #08dddd;
      }
    }
  }
}
</style>