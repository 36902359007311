<template>
  <div class="outer-page">
    <x-table :no-data-text="CA('farmers_check') ? '暂无数据' : '暂无数据查看权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @page-change="pageChange" @loadEnter="loadEnter" @loadExpend="loadExpend" @page-size-change="pageSizeChange"></x-table>
    <Modal v-model="modal.show" :title="modal.title" :width="900" @on-visible-change="modalChange" fullscreen>
      <Form ref="form" :model="form" :label-width="200" :rules="rules">
        <Row>
          <Col span="8">
          <FormItem label="企业名称" prop="farmerName">
            <Input clearable placeholder="请输入企业名称" v-model="form.farmerName" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="统一社会信用代码" prop="creditCode">
            <Input clearable placeholder="请输入统一社会信用代码" v-model="form.creditCode" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="企业类型" prop="enterpriseType">
            <Select clearable placeholder="请输入企业类型" v-model="form.enterpriseType" style="width: 260px">
              <Option v-for="(item, index) in companyData" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="种养一体">
            <Select v-model="form.isFarmPlant" style="width: 260px">
              <Option :value="item.value" v-for="item in cityList" :key="item.label">{{ item.label }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="收集工艺" prop="manureProcess">
            <Select v-model="form.manureProcess" style="width: 260px" placeholder="请选择收集工艺" label-in-value @on-change="handleManure">
              <Option v-for="item in manureProcess" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖品种" prop="typeInfo">
            <Select v-model="form.typeInfo" multiple placeholder="请选择养殖品种" style="width: 260px">
              <Option v-for="(item, index) in catData" :key="index" :value="item.id + '/' + item.name">{{ item.name }}</Option>
            </Select>
            <!-- <div v-for="item in catData" :key="item">
                {{item.id + '/' + item.name}}
              </div> -->
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="联系人" prop="name">
            <Input clearable placeholder="请输入联系人名称" v-model="form.name" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="电话号码" prop="tel">
            <Input clearable placeholder="请输入电话号码" v-model="form.tel" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="经营者身份证号码" prop="idCard">
            <Input style="width: 260px" clearable placeholder="请输入经营者身份证号码" v-model="form.idCard"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="养殖地址" prop="farmerAddress">
            <Input placeholder="请选择养殖地址" v-model="form.farmerAddress" style="width: 260px">
            <span slot="append" class="iconfont" style="cursor: pointer" @click="mapModelShow">&#xe648;</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="省市区编码" prop="region">
            <Cascader :data="areaList" v-model="form.region" :load-data="loadData" placeholder="请选择区域" style="width: 260px"></Cascader>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="生产状态" prop="productionStatus">
            <Select style="width: 260px" clearable placeholder="请选择生产状态" v-model="form.productionStatus">
              <Option value="1">正常</Option>
              <Option value="2">临时性停产</Option>
              <Option value="3">停产</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="监管人员">
            <Input style="width: 260px" clearable placeholder="请输入监管人员" v-model="form.supervisorName"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="监管人电话">
            <Input style="width: 260px" clearable placeholder="请输入监管人电话" v-model="form.supervisorPhone"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="规模类型" prop="scaleInfo">
            <Select clearable palceholder="请选择规模类型" style="width: 260px" v-model="form.scaleInfo" label-in-value @on-change="handleChange">
              <Option v-for="(item, index) in scaleList" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="法人代表" prop="legalName">
            <Input style="width: 260px" clearable placeholder="请输入法人代表" v-model="form.legalName"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="法人代表身份证" prop="legalCard">
            <Input style="width: 260px" clearable placeholder="请输入法人代表身份证" v-model="form.legalCard"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="法人代表手机号" prop="legalPhone">
            <Input style="width: 260px" clearable placeholder="请输入法人代表手机号" v-model="form.legalPhone"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="法人代表固话" prop="legalTel">
            <Input style="width: 260px" clearable placeholder="请输入法人代表固话" v-model="form.legalTel"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="环保备案" prop="environmentalProtection">
            <Input style="width: 260px" clearable placeholder="请输入环保备案" v-model="form.environmentalProtection"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="排污许可证" prop="dischargePermit">
            <Input style="width: 260px" clearable placeholder="请输入排污许可证" v-model="form.dischargePermit"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="动物防疫合格证" prop="aepc">
            <Input style="width: 260px" clearable placeholder="请输入动物防疫合格证" v-model="form.aepc"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="种畜禽经营许可证" prop="blapbl">
            <Input style="width: 260px" clearable placeholder="请输入种畜禽经营许可证" v-model="form.blapbl"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="当前存栏(头、羽、只)" prop="currentNum">
            <Input style="width: 260px" clearable placeholder="请输入现存栏数量(头、羽、只)" v-model="form.currentNum"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="能繁母猪(头)" prop="pigNum">
            <Input style="width: 260px" clearable placeholder="请输入能繁母猪(头)" v-model="form.pigNum" :disabled="disableds"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖用途">
            <Select clearable placeholder="请选择养殖用途" v-model="form.application" style="width: 260px" label-in-value @on-change="handleApplication">
              <Option v-for="(item, index) in application" :key="index" :value="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>

          <Col span="8">
          <FormItem label="异味发酵床(m³)" prop="fermentationBed">
            <Input style="width: 260px" clearable placeholder="请输入异味发酵床(立方米)" v-model="form.fermentationBed"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="养殖场数量" prop="farmCount">
            <Input placeholder="请输入养殖数量" v-model="form.farmCount" style="width: 260px"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="备注">
            <Input type="textarea" :rows="4" style="width: 260px" v-model="form.remark"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="图片上传">
            <upload-image :max="1" :clear-flag="!modal.show" v-model="form.logo"></upload-image>
          </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <Modal v-model="mapModal" fullscreen>
      <search-map v-if="mapModal" :currentData="currentData" @back-location="dealMapData"></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModal = false)">提交</Button>
        <Button @click="() => (mapModal = false)">取消</Button>
      </p>
    </Modal>
    <farmers-detail v-model="farmersDetail.show" :farmersDetailInfo="farmersDetail.info" :farmListInfo="farmListInfo" :servicePathInfo="servicePathInfo"></farmers-detail>
    <farmers-user v-model="farmersUser.show" :farmersUserId="farmersUser.id" :farmListInfo="farmListInfo" :servicePathInfo="servicePathInfo"></farmers-user>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
    <Modal :width="300" v-model="isDelete" title="是否删除" @on-ok="deletes" @on-cancel="
        () => {
          this.isDelete = false;
        }
      ">
      <p>确定删除该条消息？</p>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import searchMap from '@/components/searchMap'
import pictureView from '@/components/picture_view'
import CU from '@/common/util'
import farmersDetail from './farmersDetail.vue'
import farmersUser from './farmersUser.vue'
export default {
  name: '',
  components: {
    searchMap,
    pictureView,
    farmersDetail,
    farmersUser,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '养殖户名称',
            key: 'farmerName',
            minWidth: 100,
            fixed: 'left',
          },
          {
            title: '规模类型',
            // width: 100,
            align: 'center',
            key: 'scaleInfoName',
            minWidth: 100,
          },
          {
            title: '镇街',
            tooltip: true,
            key: 'regionCodeName',
            minWidth: 100,
          },
          {
            title: '养殖品种',
            align: 'center',
            // width: 100,
            key: 'typeName',
            minWidth: 100,
          },
          {
            title: '养殖用途',
            align: 'center',
            // width: 100,
            key: 'applicationName',
            minWidth: 100,
          },
          {
            title: '收集工艺',
            width: 100,
            key: 'manureProcessName',
          },
          {
            title: '现存栏量(头、羽、只)',
            align: 'center',
            key: 'currentNum',
            minWidth: 180,
          },
          {
            title: '能繁母猪(头)',
            align: 'center',
            key: 'pigNum',
            minWidth: 120,
          },
          {
            title: '异味发酵床(m³)',
            align: 'center',
            key: 'fermentationBed',
            minWidth: 160,
          },
          {
            title: '联系人',
            key: 'name',
            // width: 110,
            minWidth: 100,
          },
          {
            title: '电话',
            // width: 130,
            key: 'tel',
            minWidth: 100,
          },
          {
            title: '地址',
            minWidth: 150,
            key: 'farmerAddress',
            minWidth: 100,
          },
          {
            title: '操作',
            width: 220,
            fixed: 'right',
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA('farmers_user') && (
                    <a
                      style="margin-right:10px"
                      on-click={() => this.users(row)}
                    >
                      企业账号
                    </a>
                  )}
                  {this.CA('farmers_edit') && (
                    <a
                      style="margin-right:10px"
                      on-click={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('farmers_delete') && (
                    // <Poptip
                    //   confirm
                    //   transfer
                    //   title="确定删除吗?"
                    //   on-on-ok={() => this.delete(row.id)}
                    // >
                    //   <a>删除</a>
                    // </Poptip>
                    <a on-click={() => this.delete(row.id)}>删除</a>
                  )}
                  {this.CA('farmers_view') && (
                    <a
                      style="margin-left: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: '',
        show: false,
        submitLoading: false,
      },
      form: {
        id: '',
        name: '',
        tel: '',
        farmerAddress: '',
        type: [],
        typeName: [],
        typeInfo: [],
        category: '',
        farmerName: '',
        farmCount: '',
        isFarmPlant: '',
        thirdLongLat: '',
        manureProcess: '',
        manureProcessName: '',
        //社会统一信用代码
        creditCode: '',
        //企业类型
        enterpriseType: '',
        //营业期限
        businessTerm: '',
        //所属行业
        industry: '养殖业',
        //省市区编码
        region: [],
        //省市区编码字符串
        regionCode: '',
        //经营范围
        businessScope: '',
        logo: '',
        //预计年产量
        // ceilingCount:''
        options: '',
        //畜禽代码
        // livestockCode: "",
        //法人代表
        legalName: '',
        //法人代表身份证
        legalCard: '',
        //法人代表手机号
        legalPhone: '',
        //法人代表固话
        legalTel: '',
        //生产状态 1:正常，2:临时性停产，3:停产
        productionStatus: '',
        //环保备案
        environmentalProtection: '',
        //动物防疫合格证
        aepc: '',
        //种畜禽经营许可证
        blapbl: '',
        //排污许可证
        dischargePermit: '',
        //经营者身份证号码
        idCard: '',
        //监管人员名称
        supervisorName: '',
        //监管人电话
        supervisorPhone: '',
        //规模类型
        scaleInfo: '',
        scaleInfoName: '',
        //养殖用途
        application: '',
        //备注
        remark: '',
        currentNum: '',
        pigNum: '',
        fermentationBed: '',
      },
      scaleList: [],
      manureProcess: [],

      //省市区列表
      areaList: [],
      rules: {
        name: [{ required: true, message: '请填写联系人' }],
        farmerName: [{ required: true, message: '请填写企业名称' }],
        tel: [
          { required: true, message: '请填写联系电话' },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message)
                return
              }
              callback()
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        farmerAddress: [{ required: true, message: '请填写养殖地址' }],
        manureProcess: [{ required: true, message: '请选择收集工艺' }],
        scaleInfo: [{ required: true, message: '请选择规模类型' }],
        typeInfo: [{ required: true, message: '请选择养殖品种' }],
        // creditCode: [{ required: true, message: "请输入社会统一信用代码" }],
        // enterpriseType: [{ required: true, message: "请选择企业类型" }],
        // businessTerm: [{ required: true, message: "请输入营业期限" }],
        // industry: [{ required: true, message: "请输入所属行业" }],
        // region: [{ required: true, message: "请输入省市区编码" }],
        // businessScope: [{ required: true, message: "请输入经营范围" }],
        // farmCount: [{ required: true, message: "请输入养殖场数量" }],
        // livestockCode: [{ required: true, message: "请输入畜禽代码" }],
        // legalName: [{ required: true, message: "请输入法人代表" }],
        // legalCard: [{ required: true, message: "请输入法人代表身份证" }],
        // legalPhone: [
        //   { required: true, message: "请输入法人代表手机号" },
        //   {
        //     validator(rule, value, callback) {
        //       if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
        //         callback(rule.message);
        //         return;
        //       }
        //       callback();
        //     },
        //     message: "手机号格式不正确",
        //     trigger: "change",
        //   },
        // ],
        // legalTel: [
        //   { required: true, message: "请输入法人代表固话" },
        //   {
        //     validator(rule, value, callback) {
        //       if (CU.validateLandline(value)) {
        //         callback();
        //         return;
        //       }
        //       callback(rule.message);
        //     },
        //     message: "座机号格式不正确",
        //     trigger: "change",
        //   },
        // ],
        // productionStatus: [{ required: true, message: "请输入生产状态" }],
        // environmentalProtection: [
        //   { required: true, message: "请输入环保备案" },
        // ],
        // aepc: [{ required: true, message: "请输入动物防疫合格证" }],
        // blapbl: [{ required: true, message: "请输入种畜禽经营许可证" }],
        // dischargePermit: [{ required: true, message: "请输入排污许可证" }],
        // idCard: [{ required: true, message: "请输入经营者身份证号码" }],
      },
      catData: [],
      area:[],
      application: [],
      selAddressShow: false,
      currentData: null,
      mapModal: false,
      companyData: [],
      picture: {
        show: false,
        src: '',
      },
      farmersDetail: {
        show: false,
        info: {},
      },
      farmersUser: {
        show: false,
        id: '',
      },
      cityList: [
        { value: '1', label: '是' },
        { value: '2', label: '否' },
      ],
      farmListInfo: [],
      servicePathInfo: '',
      deleteId: '',
      isDelete: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: '新增养殖户',
            ca: 'farmers_add',
          },
          loadModel: {
            url: 'https://castoff.hogdata.cn/app/规模养殖场粪污资源化利用情况调查表.xlsx',
          },
          loadEnter: {
            loadName: '导入',
            ca: 'farmers_import',
          },
          loadExpend: {
            loadExpendName: '导出',
            ca: 'farmers_export',
          },
          width: 220,
          filterBox: [
            {
              conditionName: '养殖户名称',
              component: 'input',
              field: 'farmerName',
              defaultValue: '',
            },
            {
              conditionName: '规模类型',
              component: 'select',
              field: 'scaleInfo',
              defaultValue: '',
              data: this.scaleList,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
            {
              conditionName: '镇街',
              component: 'cascader',
              field: 'regionCode',
              defaultValue: [],
              data: this.area,
              isLoadData: true,
              loadData: this.loadData,
            },
            {
              conditionName: '地址',
              component: 'input',
              field: 'farmerAddress',
              defaultValue: '',
            },
            {
              conditionName: '养殖品种',
              component: 'select',
              field: 'typeName',
              defaultValue: '',
              data: this.catData,
              parameterField: 'name',
              showField: 'name',
              clearable: true,
            },
            {
              conditionName: '企业类型',
              component: 'select',
              field: 'enterpriseType',
              defaultValue: '',
              data: this.companyData,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
            // {
            //   conditionName: "养殖区域",
            //   component: "cascader",
            //   field: "regionCode",
            //   defaultValue: [],
            //   data: this.areaList,
            //   isLoadData: true,
            //   loadData: this.loadData,
            // },
          ],
        },
        page: this.page,
      }
      return config
    },
    disableds() {
      let bool = true
      this.form.typeInfo.map((item) => {
        if (item.indexOf('猪') != -1) {
          bool = false
        }
      })
      return bool
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement('input')
      input.type = 'file'
      input.onchange = (e) => {
        if (!input.files || input.files.length === 0) return
        //通过FormData构造函数创建一个空对象
        let formdata = new FormData()
        //通过append()方法来追加数据
        formdata.append('file', input.files[0])
        formdata.append('userId', localStorage.getItem('userId'))
        formdata.append('companyNo', localStorage.getItem('companyNo'))
        formdata.append('category', this.user.category + 1)
        this.$post(this.$api.FAMRMING.UPLOAD, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
          this.$Message.success('导入成功')
          this.getList()
        })
      }
      input.click()
    },
    getManureProcess() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '25',
      }).then((res) => {
        this.manureProcess = res.list
      })
    },
    handleManure(o) {
      if (o?.label) {
        this.form.manureProcessName = o.label
      }
    },
    handleChange(o) {
      if (o?.label) {
        this.form.scaleInfoName = o.label
      }
    },
    handleApplication(o) {
      if (o?.label) {
        this.form.applicationName = o.label
      }
    },
    dealMapData(mapData) {
      this.form.farmerAddress = mapData.address
      this.form.thirdLongLat = mapData.thirdLongLat
    },
    mapModelShow() {
      this.currentData = this.form.thirdLongLat
      this.mapModal = true
    },
    getList() {
      if (!this.CA('farmers_check')) return
      this.table.loading = true
      this.$post(this.$api.FAMRMING.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: '2',
      }).then((res) => {
        this.catData = res.list
      })
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '203',
      }).then((res) => {
        this.companyData = res.list
      })
    },
    //规模类型
    getScale() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: 291,
      }).then((res) => {
        this.scaleList = res.list
      })
    },
    search(value) {
      console.log(value)
      let n
      if (value.regionCode) {
        for (let i = 0; i < value.regionCode.length; i++) {
          n = value.regionCode[value.regionCode.length - 1]
        }
        let obj = {
          ...value,
          regionCode: n,
        }
        this.search_data = obj
        this.getList()
      } else {
        let obj = {
          ...value,
        }
        this.search_data = obj
        this.getList()
      }
    },
    add() {
      this.modal = {
        show: true,
        title: '新增养殖户',
        submitLoading: false,
      }
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.typeInfo = []
      this.form.type = row.type.split(',')
      this.form.typeName = row.typeName.split(',')
      this.form.region = row.regionCode.split(',')
      this.form.logo = this.getImgUrl(row.servicePath, row.logo)
      if (this.form.farmCount) {
        this.form.farmCount = row.farmCount.toString()
      } else {
        this.form.farmCount = ''
      }

      if (this.form.productionStatus) {
        this.form.productionStatus = row.productionStatus.toString()
      } else {
        this.form.productionStatus = ''
      }
      for (let i = 0; i < this.form.type.length; i++) {
        let str = this.form.type[i] + '/' + this.form.typeName[i]
        this.form.typeInfo.push(str)
      }
      // console.log(this.form.typeInfo);
      // this.form.typeInfo = row.typeName
      this.modal = {
        show: true,
        title: '编辑养殖户',
        submitLoading: false,
      }
    },
    delete(id) {
      this.isDelete = true
      this.deleteId = id
    },
    deletes() {
      this.$post(this.$api.FAMRMING.DELETE, {
        id: this.deleteId,
      }).then(() => {
        this.$Message.success('删除成功！')
        this.getList()
      })
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500000',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          item.loading = false
          item.children = []
        })
        this.areaList = res
      })
    },
    //省市区列表接口
    getArea() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500100',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
        })
        this.area = res
      })
    },
    loadData(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
            if (item.hasChild == 'true') {
              item.loading = false
              item.children = []
            }
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        let url
        if (params.id) {
          url = this.$api.FAMRMING.UPDATE
        } else {
          url = this.$api.FAMRMING.ADD
        }
        params.type = []
        params.typeName = []
        params.category = +this.user.category + 1
        this.form.typeInfo.forEach((item) => {
          params.type.push(item.split('/')[0])
          params.typeName.push(item.split('/')[1])
        })
        params.type = params.type.toString()
        params.farmCount = params.farmCount.toString()
        params.regionCode = this.form.region.join(',')
        delete params.region
        params.typeName = params.typeName.toString()
        delete params.typeInfo
        let index = this.form.logo ? this.form.logo.indexOf('/', 10) : -1
        if (index !== -1) {
          params.logo = this.form.logo.substring(index + 1)
        }
        this.modal.submitLoading = true
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? '修改成功！' : '增加成功！')
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return
      this.form = {
        id: '',
        name: '',
        tel: '',
        farmerAddress: '',
        type: [],
        typeName: [],
        typeInfo: [],
        farmerName: '',
        thirdLongLat: '',
        creditCode: '',
        manureProcess: '',
        manureProcessName: '',
        enterpriseType: '',
        businessTerm: '',
        industry: '养殖业',
        regionCode: '',
        businessScope: '',
        region: [],
        logo: '',
        farmCount: '',
        // livestockCode: "",
        legalName: '',
        legalCard: '',
        legalPhone: '',
        legalTel: '',
        productionStatus: '',
        environmentalProtection: '',
        aepc: '',
        blapbl: '',
        dischargePermit: '',
        idCard: '',
        supervisorName: '',
        supervisorPhone: '',
        scaleInfo: '',
        scaleInfoName: '',
        application: '',
        remark: '',
        currentNum: '',
        pigNum: '',
        fermentationBed: '',
      }
      this.$refs.form.resetFields()
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },

    loadExpend() {
      let columns = this.table.columns.map((item) => item.title)
      columns.pop()
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          if (column.title == 'LOGO') {
            return this.getImgUrl(row.servicePath, row.logo)
          } else if (column.title == '养殖场(个)') {
            return row.farmCount + '个'
          }
          return row[column.key]
        })
        return rowData
      })

      CU.exportExcel(columns, data, '养殖户企业')
    },
    //详情
    checkInfo(row) {
      this.$post(this.$api.FAMRMING.INFO, {
        companyNo: row.companyNo,
        userId: row.userId,
      }).then((res) => {
        this.farmListInfo = res.farmList
        this.servicePathInfo = res.servicePath
        this.farmersDetail = {
          show: true,
          info: row,
        }
      })
    },
    //企业账号
    users(row) {
      this.farmersUser = {
        show: true,
        id: row.id,
      }
    },
    //养殖用途
    getApplication() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '526',
      }).then((res) => {
        this.application = res.list
      })
    },
  },
  mounted() {
    this.getList()
    this.getCategory()
    this.getCompanyCategory()
    this.getManureProcess()
    this.getApplication()
    this.getAreaList()
    this.getArea()
    this.getScale()
  },
}
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>
